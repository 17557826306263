/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/assets/models/3k_Standart_mit_Logo.gltf"
  );
  return (
    <group ref={group} {...props} dispose={null} title="3D Swiss Block® Block">
      <group
        position={[0.27, 0.5, -1.45]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.01, 0.01, 0.01]}
      >
        <mesh
          geometry={nodes["3K_STANDART006"].geometry}
          material={materials["SWISSBLOCK_schriftzug [Konvertiert]"]}
        />
        <mesh
          geometry={nodes["3K_STANDART006_1"].geometry}
          material={materials["concretee.jpg (2)"]}
        />
        <mesh
          geometry={nodes["3K_STANDART006_2"].geometry}
          material={materials["feuerverzinkt.001"]}
        />
        <mesh
          geometry={nodes["3K_STANDART006_3"].geometry}
          material={materials["SWISSBLOCK_schriftzug [40opacity]"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/models/3k_Standart_mit_Logo.gltf");
