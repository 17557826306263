import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import WrapperSmallEqTopBtm from "./Layout/WrapperSmallEqTopBtm";
import { Background } from "react-imgix";
import { imgix } from "../data/data";
import { superScriptRHelperHTML } from "../data/superscriptHelper";

interface LandingProps {
  image: {
    url: string;
    title: string;
  };
  text: string;
}

const LandingDiv = styled(Background)`
  height: 25vh;
  width: 100vw;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h1 {
    color: ${colors.green};
    font: 26px / 30px "Oswald Bold";
    text-align: right;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    height: 55vh;
    h1 {
      font: 45px / 53px "Oswald Bold";
    }
  }
  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    h1 {
      font: 56px / 66px "Oswald Bold";
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: 100vh;

    h1 {
      font: 100px / 112px "Oswald Bold";
    }
  }
  @media screen AND (min-width: ${breakpoints.desktopXL}px) {
    h1 {
      font: 110px / 124px "Oswald Bold";
    }
  }
  //not 100vh in mobile, max-width!
`;

const Landing: React.FC<LandingProps> = ({ image, text }) => (
  <LandingDiv
    imgixParams={imgix.fullImg}
    src={image.url}
    htmlAttributes={{ title: image.title }}
  >
    <WrapperSmallEqTopBtm>
      <div
        dangerouslySetInnerHTML={{ __html: superScriptRHelperHTML(text) }}
      ></div>
    </WrapperSmallEqTopBtm>
  </LandingDiv>
);

export default Landing;
