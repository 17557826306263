import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    column-gap: 40px;
  }
`;

const ThreeGrid: React.FC = ({ children }) => <GridDiv>{children}</GridDiv>;

export default ThreeGrid;
