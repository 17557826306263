import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import GreenLead from "../components/GreenLead";
import WhiteLead from "../components/WhiteLead";
import Landing from "../components/Landing";
import FWImage from "../components/FWImage";
import GreenThreeGrid from "../components/GreenThreeGrid";
import Model3D from "../components/Model3D";

interface IndexProps {
  data: {
    cms: {
      entry: {
        title: string;
        siteId: number;
        landingBild: [{ url: string; title: string }];
        landingTitel: string;
        columnGrunerHintergrundTexte: [
          {
            text: string;
          }
        ];
        whiteLead: string;
        fwImageGrunerBlockMatrix: {
          greenLead: string;
          fullWidthImage: [
            {
              url: string;
              title: string;
            }
          ];
        };
      };
    };
  };
}

export const IndexQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_home_Entry {
          landingBild {
            url
            title
          }
          metaBeschreibung
          landingTitel
          columnGrunerHintergrundTexte {
            ... on CMS_columnGrunerHintergrundTexte_textColumn_BlockType {
              text
            }
          }
          whiteLead
          fwImageGrunerBlockMatrix {
            ... on CMS_fwImageGrunerBlockMatrix_fwImageGrunerTextBlock_BlockType {
              greenLead
              fullWidthImage {
                url
                title
              }
            }
          }
        }
      }
    }
  }
`;

const Index: React.FC<IndexProps> = ({ data }) => (
  <DefaultLayout
    siteId={data.cms.entry.siteId}
    //links={data.cms.entry.localized}
    green
    beschreibung={data.cms.entry.metaBeschreibung}
  >
    <Landing
      image={data.cms.entry.landingBild[0]}
      text={data.cms.entry.landingTitel}
    />
    <GreenThreeGrid inhalt={data.cms.entry.columnGrunerHintergrundTexte} />
    <Model3D />
    <WhiteLead inhalt={data.cms.entry.whiteLead} />
    {data.cms.entry.fwImageGrunerBlockMatrix.map((entry, index) => (
      <div key={index}>
        <FWImage image={entry.fullWidthImage[0]} />
        <GreenLead inhalt={entry.greenLead} />
      </div>
    ))}
  </DefaultLayout>
);

export default Index;
