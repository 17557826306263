import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { superScriptRHelperHTML } from "../data/superscriptHelper";
import { breakpoints, colors } from "../styles/variables";
import ThreeGrid from "./Layout/Grids/ThreeGrid";
import Wrapper from "./Layout/Wrapper";

interface GreenThreeGridProps {
  inhalt: [
    {
      text: string;
    }
  ];
}

const GreenGridDiv = styled.div`
  background: ${colors.green};

  span {
    div {
      margin-bottom: 30px;
    }
  }

  .moreButton {
    display: none;
  }

  h3 {
    color: #fff;
    margin-bottom: 7px;
  }

  p {
    font: 14px / 21px "Oswald Light";
  }

  > div > div > div:nth-of-type(2),
  > div > div > div:nth-of-type(1) {
    margin-bottom: 19px;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    h3 {
      margin-bottom: 14px;
    }
    p {
      font: 18px / 28px "Oswald Light";
    }
    > div > div > div:nth-of-type(2),
    > div > div > div:nth-of-type(1) {
      margin-bottom: 30px;
    }
  }

  @media screen AND (min-width: ${breakpoints.tabletXL}px) {
    span {
      div {
        max-height: 140px;
        overflow: hidden;
        margin-bottom: 20px;
      }
    }

    &.expanded {
      span {
        div {
          max-height: none;
          overflow: hidden;
          margin-bottom: 20px;
        }
      }

      .moreButton {
        display: none;
      }
    }

    .moreButton {
      display: block;
      cursor: pointer;
      margin-bottom: 0;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    span {
      div {
        max-height: 190px;
        overflow: hidden;
      }
    }
    h3 {
      margin-bottom: 15px;
    }
    p {
      font: 21px / 32px "Oswald Light";
    }
    > div > div > div:nth-of-type(2),
    > div > div > div:nth-of-type(1) {
      margin-bottom: 0;
    }
  }
`;

const GreenThreeGrid: React.FC<GreenThreeGridProps> = ({ inhalt }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <GreenGridDiv className={isExpanded ? "expanded" : ""}>
      <Wrapper>
        <ThreeGrid>
          {inhalt.map((obj, index) => (
            <span key={index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: superScriptRHelperHTML(obj.text),
                }}
                key={index}
              ></div>
            </span>
          ))}
        </ThreeGrid>
        <div className="moreButton" onClick={() => setIsExpanded(true)}>
          <img src="/assets/gestaltungselemente/select-arrow-down.svg" />
        </div>
      </Wrapper>
    </GreenGridDiv>
  );
};

export default GreenThreeGrid;
